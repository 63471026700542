import { ref } from '@vue/composition-api'

export function useValidation () {
  const required = ref(v => !!v || 'Required')
  const requiredArr = ref(v => v.length > 0 || 'Required')
  const email = ref(v => /.+@.+\..+/.test(v) || 'E-mail must be valid')
  const pwd = ref(v => (/[a-z]/.test(v) && /[A-Z]/.test(v) && /[0-9]/.test(v)) || 'Must contain a number and both lower/upper case letters')
  const minLength8 = ref(v => (!v || v.length >= 8) || 'Min length is 8 characters')
  const minLength10 = ref(v => (!v || v.length >= 10) || 'Min length is 10 characters')
  const minLength20 = ref(v => (!v || v.length >= 20) || 'Min length is 20 characters')
  const minLength50 = ref(v => (!v || v.length >= 50) || 'Min length is 50 characters')
  const minLength100 = ref(v => (!v || v.length >= 100) || 'Min length is 100 characters')
  const maxLength25 = ref(v => (!v || v.length <= 25) || 'Max length is 25 characters')
  const maxLength50 = ref(v => (!v || v.length <= 50) || 'Max length is 50 characters')
  const maxLength100 = ref(v => (!v || v.length <= 100) || 'Max length is 100 characters')
  const maxLength150 = ref(v => (!v || v.length <= 150) || 'Max length is 150 characters')
  const maxLength160 = ref(v => (!v || v.length <= 160) || 'Max length is 160 characters')
  const maxLength200 = ref(v => (!v || v.length <= 200) || 'Max length is 200 characters')
  const maxLength250 = ref(v => (!v || v.length <= 250) || 'Max length is 250 characters')

  return {
    required,
    requiredArr,
    email,
    pwd,
    minLength8,
    minLength10,
    minLength20,
    minLength50,
    minLength100,
    maxLength25,
    maxLength50,
    maxLength100,
    maxLength150,
    maxLength160,
    maxLength200,
    maxLength250
  }
}
