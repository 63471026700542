<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ id === 'new' ? 'Add New Notification' : 'Edit Notification Details' }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="$router.push('/mailroom/admin/notifications')" outlined>Back to Notifications List</v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form">
        <v-text-field v-model="title" :rules="[required]" label="Notification Title" outlined></v-text-field>
        <v-checkbox v-model="email.include" label="Include Email"></v-checkbox>
        <template v-if="email.include">
          <v-text-field v-model="email.subject" :rules="[required]" label="Email Subject" outlined></v-text-field>
          <h4>Email Body</h4>
          <html-editor v-model="email.body"></html-editor>
        </template>
        <v-checkbox v-model="text.include" label="Include Text Message"></v-checkbox>
        <v-textarea v-if="text.include" v-model="text.message" :rules="[required, maxLength160]" label="Text Message" outlined counter="160"></v-textarea>
        <h3 style="margin:.5em 0">Selection Criteria (what packages should receive this notification)</h3>
        <v-row>
          <v-col>
            <v-select v-model="select.status" :items="statusOptions" label="Package Status" multiple outlined chips small-chips deletable-chips>
              <template v-slot:prepend-item>
                <v-list-item @click="select.status = select.status.length === statusOptions.length ? [] : statusOptions">
                  <v-list-item-action>
                    <v-checkbox :indeterminate="select.status.length > 0 && select.status.length < statusOptions.length" :value="select.status.length > 0"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-title>{{ select.status.length === statusOptions.length ? 'Deselect' : 'Select' }} All</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select v-model="select.location" :items="locationOptions" label="Package Location" multiple outlined>
              <template v-slot:selection="{ item, index }">
                <template v-if="select.location.length === locationOptions.length">
                  <span v-if="index === 0" class="grey--text text-caption">**All Selected**</span>
                </template>
                <v-chip v-else-if="index <= 2">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-else-if="index === 3" class="grey--text text-caption">(+{{ select.location.length - 2 }} others)</span>
              </template>
              <template v-slot:prepend-item>
                <v-list-item @click="select.location = select.location.length === locationOptions.length ? [] : locationOptions">
                  <v-list-item-action>
                    <v-checkbox :indeterminate="select.location.length > 0 && select.location.length < locationOptions.length" :value="select.location.length > 0"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-title>{{ select.location.length === locationOptions.length ? 'Deselect' : 'Select' }} All</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select v-model="select.person" :items="personOptions" label="Recipient Type" multiple outlined chips small-chips deletable-chips>
              <template v-slot:prepend-item>
                <v-list-item @click="select.person = select.person.length === personOptions.length ? [] : personOptions">
                  <v-list-item-action>
                    <v-checkbox :indeterminate="select.person.length > 0 && select.person.length < personOptions.length" :value="select.person.length > 0"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-title>{{ select.person.length === personOptions.length ? 'Deselect' : 'Select' }} All</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-text-field v-model="select.notifications" label="# of Notifications Sent" type="number" min="0" outlined>
              <template v-slot:append>
                <v-icon v-if="select.notifications != null && select.notifications != ''" @click="select.notifications = null">fas fa-times-circle</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="select.age" label="Days on shelf" type="number" min="0" outlined>
              <template v-slot:append>
                <v-icon v-if="select.age != null && select.age != ''" @click="select.age = null">fas fa-times-circle</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <h3 style="margin:.5em 0">After sending the notification, do these updates</h3>
        <v-row>
          <v-col>
            <v-select v-model="after.status" :items="afterStatusOptions" label="Change Status to" outlined></v-select>
          </v-col>
        </v-row>
        <h3 style="margin:.5em 0">When should this notification be sent?</h3>
        <v-row>
          <v-col>
            <v-switch v-model="send.auto" :label="'Send ' + (send.auto ? 'Automatically' : 'Manually')"></v-switch>
          </v-col>
          <v-col v-if="send.auto">
            <v-select v-model="send.days" :items="dayOptions" :rules="[requiredArr]" label="Days to send notification" outlined multiple chips small-chips deletable-chips>
              <template v-slot:prepend-item>
                <v-list-item @click="send.days = send.days.length === dayOptions.length ? [] : dayOptions">
                  <v-list-item-action>
                    <v-checkbox :indeterminate="send.days.length > 0 && send.days.length < dayOptions.length" :value="send.days.length > 0"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-title>{{ send.days.length === dayOptions.length ? 'Deselect' : 'Select' }} All</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="send.auto">
            <time-picker v-model="send.time" :rules="[required]" label="Time of day to send"></time-picker>
          </v-col>
        </v-row>
      </v-form>
      <v-alert v-for="(error, index) in errors" :key="'error-' + index" color="error">{{ error }}</v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" text @click="save()">Save</v-btn>
      <v-btn text @click="$router.push('/mailroom/admin/notifications')">Cancel</v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn v-if="!send.auto" color="info" text @click="sendNotifications()">Send This Notification Now</v-btn> -->
      <send-notice :notification="{ _id: id, select, title }" button-text="Send This Notification Now"></send-notice>
    </v-card-actions>
  </v-card>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { useValidation } from '../../../helpers/validation'

export default {
  components: {
    HtmlEditor: () => import('@/components/forms/HTMLEditor'),
    TimePicker: () => import('@/components/forms/inputs/TimePicker'),
    SendNotice: () => import('@/components/mailroom/admin/sendNotice')
  },
  setup (props, { root }) {
    const { required, requiredArr, maxLength160 } = useValidation()
    const user = computed(() => root.$store.state.user)
    const id = computed(() => root.$route.params.id)
    const form = ref(null)
    const title = ref('')
    const email = ref({
      include: false,
      subject: '',
      body: ''
    })
    const text = ref({
      include: false,
      message: ''
    })
    const select = ref({
      status: [],
      location: [],
      person: [],
      notifications: null,
      age: null
    })
    const after = ref({
      status: null
    })
    const send = ref({
      auto: false,
      days: [],
      time: ''
    })

    const statusOptions = ref([])
    const afterStatusOptions = ref([{ text: '--Do not change status--', value: null }])
    const locationOptions = ref([])
    const personOptions = ref(['Student', 'Faculty', 'Staff', 'Department'])

    const noticeServ = root.$feathers.service('mailroom/notification')

    onMounted(() => {
      const optionService = root.$feathers.service('forms/select-options')
      optionService.find({ query: { name: 'Mailroom: Package Status' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            statusOptions.value = options
            options.forEach((option) => afterStatusOptions.value.push(option))
          })
        }
      })
      optionService.find({ query: { name: 'Mailroom: Package Location' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            locationOptions.value = options
          })
        }
      })
      if (id.value !== 'new') {
        noticeServ.get(id.value).then((data) => {
          title.value = data.title
          if ('email' in data) {
            for (let l in data.email) email.value[l] = data.email[l]
          }
          if ('text' in data) {
            for (let l in data.text) text.value[l] = data.text[l]
          }
          if ('select' in data) {
            for (let l in data.select) select.value[l] = data.select[l]
          }
          if ('send' in data) {
            for (let l in data.send) send.value[l] = data.send[l]
          }
          if ('after' in data) {
            for (let l in data.after) after.value[l] = data.after[l]
          }
        })
      }
    })

    const dayOptions = ref([
      { text: 'Monday', value: 'M' },
      { text: 'Tuesday', value: 'T' },
      { text: 'Wednesday', value: 'W' },
      { text: 'Thursday', value: 'R' },
      { text: 'Friday', value: 'F' },
      { text: 'Saturday', value: 'S' }
    ])

    const errors = ref([])
    function save () {
      errors.value = []
      // Make sure at least one selection criteria is selected
      if (select.value.status.length === 0 && select.value.location.length === 0 && select.value.person.length === 0 && (select.value.notifications == null || select.value.notifications === '') && (select.value.age == null || select.value.age === '')) {
        errors.value.push('At least one selection criteria must be set (Package Status, Package Location, Recipient Type, # of Notifications Sent, or Days on Shelf)')
      }
      // Make sure the "change status to" does not include any of the criteria package statuses
      for (let i = 0; i < select.value.status.length; i++) {
        if (select.value.status[i] === after.value.status) {
          errors.value.push('You cannot set the "change to" status to a status that is in the selection criteria')
          break
        }
      }
      if (form.value.validate()) {
        if (errors.value.length > 0) return
        let obj = {
          title: title.value,
          email: email.value,
          text: text.value,
          updatedBy: user,
          updatedDate: new Date(),
          select: select.value,
          send: send.value,
          after: after.value
        }
        if (id.value === 'new') {
          noticeServ.create(obj).catch((e) => {
            root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'Error saving notification: ' + e })
          }).then((data) => {
            root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Notification created.' })
            root.$router.replace('/mailroom/admin/notifications/' + data._id)
            root.$router.push('/mailroom/admin/notifications')
          })
        } else {
          noticeServ.patch(id.value, obj).catch((e) => {
            root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'Error saving notification: ' + e })
          }).then((data) => {
            root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Notification updated.' })
            root.$router.push('/mailroom/admin/notifications')
          })
        }
      }
    }

    function sendNotifications () {
      // root.$store.dispatch('mailroom/sendNotifications', { notificationId: id.value })
    }

    return {
      user,
      id,
      form,
      title,
      email,
      text,
      select,
      statusOptions,
      locationOptions,
      personOptions,
      after,
      afterStatusOptions,
      errors,
      save,
      send,
      dayOptions,
      sendNotifications,
      required,
      requiredArr,
      maxLength160
    }
  }
}
</script>
